import * as R from 'ramda'

import { LOAD_DATA_SUCCESS } from './actions'

const initialState: any = {
  orders: [],
}

const calculateTotalMeals = (orders: any): any => {
  let totalMeals = 0

  R.forEach((order: any): any => {
    if (order.cartItems && order.cartItems.length) {
      const bundleProduct = R.find((cartItem: any): any => cartItem.price > 0, order.cartItems)

      if (!bundleProduct.name) {
        return
      }

      const bundleProductRegexGroups = bundleProduct.name.match(/[^0-9]+([0-9]+)[^0-9]+([0-9]+).+/)
      totalMeals += bundleProductRegexGroups && bundleProductRegexGroups.length > 2
        ? parseInt(bundleProductRegexGroups[1], 10) * parseInt(bundleProductRegexGroups[2], 10)
        : 0
    }
  }, orders)

  if (totalMeals === 0) {
    R.forEach((order: any) => {
      if (order.cartItems) {
        R.forEach((cartItem: any) => {
          if (cartItem.itemCount) {
            totalMeals += parseInt(cartItem.itemCount.match(/\d+/)[0], 10)
          }
        }, order.cartItems)
      }
    }, orders)
  }

  return totalMeals
}

const calculateTotalPrice = (orders: any): any => {
  let totalPrice = 0

  R.forEach((order: any): any => {
    totalPrice += parseFloat(order.totalPrice || 0)
  }, orders)

  return totalPrice
}

export default (state = initialState, action: any): any => {
  const filteredOrders = action.data
    ? R.reject((order: any): any => order.deliveryTime === '0', action.data)
    : action.data
  switch (action.type) {
    case LOAD_DATA_SUCCESS: {
      return {
        ...state,
        orders: filteredOrders,
        totalMeals: calculateTotalMeals(filteredOrders),
        totalOrders: filteredOrders.length,
        totalPrice: calculateTotalPrice(filteredOrders),
        previousTotalMeals: state.totalMeals,
        previousTotalOrders: state.totalOrders,
        previousTotalPrice: state.totalPrice,
      }
    }
    default: {
      return state
    }
  }
}

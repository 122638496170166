import { applyMiddleware, createStore, compose } from 'redux'
import thunk from 'redux-thunk'
import rootReducer from './rootReducer'

const configureStore = (initialState?: any): any => createStore(
  rootReducer,
  initialState,
  compose(applyMiddleware(thunk)),
)

export default configureStore

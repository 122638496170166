import React from 'react'
import './App.css'

const App: React.FC = () => (
  <div>
    <h2>Dashboard on poistettu käytöstä. Tulevat tuotteet (palvelutiskiraportti) löytyvät
      hallintapaneelista Tilaukset -&gt; Lataa tilatut tuotteet.</h2>
  </div>
)

export default App

import fetch from 'cross-fetch'

export const LOAD_DATA_SUCCESS = 'LOAD_DATA_SUCCESS'

// eslint-disable-next-line
const queryParamsString = window.location.search
const searchParams = new URLSearchParams(queryParamsString)
const storeId = searchParams.get('storeId') || 'mealkits'

export const loadStats = () => (dispatch: any): any => fetch(
  `${process.env.REACT_APP_API_URL}?${new URLSearchParams({ storeId })}`, {
    headers: {
      Authorization: process.env.REACT_APP_API_KEY || '',
    },
  },
)
  .then((response: any): any => {
    if (response.ok) {
      return response.json()
    }
    throw new Error(response.statusText)
  })
  .then((data: any): any => {
    dispatch({ type: LOAD_DATA_SUCCESS, data })
  })
